<template>
  <div class="product-swiper">
    <div class="product-swiper__container">
      <swiper
        class="product-swiper__thumbs"
        :slides-per-view="5"
        :slides-per-group="5"
        :navigation="navigation"
        :speed="550"
        :direction="'vertical'"
        :modules="modules"
        :freeMode="true"
        :watchSlidesProgress="true"
        @swiper="setThumbsSwiper"
        :breakpoints="breakpoints"
        :allowTouchMove="false"
      >
        <template v-if="newImages[0]">
          <swiper-slide v-for="(image, i) in newImages" :key="i">
            <img :src="cdn + image" alt="" />
          </swiper-slide>
        </template>
        <template v-else>
          <swiper-slide>
            <img :src="require('../assets/images/logo/nophoto.png')" alt="" />
          </swiper-slide>
        </template>
      </swiper>
      <div class="product-swiper__navigation">
        <div class="product-swiper__navigation-left">
          <svg
            width="15"
            height="10"
            viewBox="0 0 15 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 9L7.5 2L1 9"
              stroke="#D81921"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </div>
        <div class="product-swiper__navigation-right">
          <svg
            width="15"
            height="10"
            viewBox="0 0 15 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 9L7.5 2L1 9"
              stroke="#D81921"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>
    </div>

    <swiper
      class="product-swiper__top"
      :modules="modules"
      :speed="400"
      :thumbs="{ swiper: thumbsSwiper }"
    >
      <template v-if="newImages[0]">
        <swiper-slide v-for="(image, i) in newImages" :key="i">
          <div v-if="sale" class="sale">{{ $t("sale") }}</div>
          <img :src="cdn + image" alt="" />
        </swiper-slide>
      </template>
      <template v-else>
        <swiper-slide>
          <div v-if="sale" class="sale">{{ $t("sale") }}</div>
          <img :src="require('../assets/images/logo/nophoto.png')" alt="" />
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { ref } from "vue";
import { Navigation, Thumbs, FreeMode, EffectFade } from "swiper";
import { mapState } from "vuex";

export default {
  name: "ThumbsSwiper",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    productImages: {
      type: Array,
    },
    mainImage: {
      type: String,
    },
    sale: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    this.newImages.push(this.mainImage);
    this.productImages.map((item) =>
      this.mainImage !== item ? this.newImages.push(item) : null
    );
  },
  watch: {
    mainImage() {
      this.newImages = [];
      this.newImages.push(this.mainImage);
      this.productImages.map((item) =>
        this.mainImage !== item ? this.newImages.push(item) : null
      );
    },
  },
  data() {
    return {
      newImages: [],
      navigation: {
        nextEl: ".product-swiper__navigation-right",
        prevEl: ".product-swiper__navigation-left",
      },
      breakpoints: {
        0: {
          spaceBetween: 5,
        },
        1366.98: {
          spaceBetween: 10,
        },
      },
    };
  },
  computed: {
    ...mapState(["cdn"]),
  },
  setup() {
    let thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };
    return {
      modules: [Navigation, Thumbs, FreeMode, EffectFade],
      thumbsSwiper,
      setThumbsSwiper,
    };
  },
};
</script>

<style lang="scss" scoped>
.product-swiper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0 15px;
  height: 563px;
  @media (max-width: $pc + px) {
    height: 463px;
  }
  @media (max-width: $tablet + px) {
    margin: 0 auto 0 0;
  }
  @media (max-width: $mobile + px) {
    height: 369.5px;
  }
  @media (max-width: $mobileSmall + px) {
    height: 276px;
    gap: 0 21px;
  }

  &__container {
    position: relative;
    height: 100%;
    padding: 24px 0;
    @media (max-width: $mobileSmall + px) {
      padding: 17px 0;
    }
  }
  &__thumbs,
  &__top {
    height: 100%;
    .swiper-slide {
      border: 1px solid #ececec;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        object-fit: contain;
        height: 95%;
        max-width: 100%;
        max-height: 95%;
      }
    }
  }

  &__thumbs {
    width: 104px;
    .swiper-slide {
      cursor: pointer;
      &-thumb-active {
        border-color: #d81921;
      }
    }
    @media (max-width: $pc + px) {
      width: 80px;
    }
    @media (max-width: $mobile + px) {
      width: 63px;
    }
    @media (max-width: $mobileSmall + px) {
      width: 46px;
    }
  }

  &__top {
    width: 614px;
    &.swiper {
      margin: 0;
    }
    @media (max-width: $pc + px) {
      width: 514px;
    }
    @media (max-width: $mobile + px) {
      width: 454.5px;
    }
    @media (max-width: $mobileSmall + px) {
      width: 276px;
    }
  }

  &__navigation {
    z-index: 21;
    top: 0;
    left: calc(50% - 7.5px);
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .swiper-button-disabled {
      svg {
        path {
          stroke: #c4c4c4;
        }
      }
    }

    @media (max-width: $mobileSmall + px) {
      left: calc(50% - 5px);
    }

    &-left,
    &-right {
      cursor: pointer;
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $mobileSmall + px) {
        width: 10px;
        height: 10px;
        img {
          height: 5px;
          height: 10px;
        }
      }
    }

    &-right {
      transform: rotate(180deg);
    }
  }
}
.sale {
  position: absolute;
  top: 20px;
  left: -5px;
  min-width: 78px;
  height: 26px;
  background: url("../assets/images/sale.png");
  background-repeat: no-repeat;
  font-weight: 500;
  font-size: 17px;
  color: #ffffff;
  padding-left: -5px;
  padding-top: 2px;
  text-align: center;
}
</style>