<template>
  <div class="product-card" v-if="product !== null">
    <div class="product-card__container _container">
      <BreadCrumbs
        :items="[
          [$t('main'), '/'],
          [$t('catalog'), '/catalog/kolodki'],
          [product.title],
        ]"
      />
      <div class="product-card__breadcrumbs"></div>
      <div class="product-card__body">
        <div class="product-card__content">
          <div class="product-card__swiper">
            <ThumbsSwiper
              :main-image="mainImg"
              :product-images="imagesPP"
              :sale="product.funds_1 || product.funds_2 || false"
            ></ThumbsSwiper>
          </div>
          <div class="product-card__info product-info">
            <div class="product-info__top">
              <div class="product-info__group">
                <button
                  class="product-info__icon"
                  ref="favoriteBtn"
                  @click="addFavoriteProduct"
                  :class="{ active: favorite || product.favourite }"
                >
                  <span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_514_7172)">
                        <path
                          d="M2.04673 11.0474L9.99935 19L17.952 11.0474C18.836 10.1633 19.3327 8.96429 19.3327 7.71404C19.3327 5.11055 17.2221 3 14.6186 3C13.3684 3 12.1694 3.49666 11.2853 4.38071L9.99935 5.66667L8.71339 4.38071C7.82934 3.49666 6.6303 3 5.38006 3C2.77657 3 0.666016 5.11055 0.666016 7.71404C0.666016 8.96429 1.16267 10.1633 2.04673 11.0474Z"
                          fill="none"
                        />
                        <path
                          d="M14.5835 1.59711C13.6451 1.6117 12.7272 1.87365 11.9224 2.3565C11.1177 2.83935 10.4546 3.526 10.0001 4.34711C9.54566 3.526 8.88257 2.83935 8.07783 2.3565C7.27308 1.87365 6.35517 1.6117 5.41679 1.59711C3.92091 1.6621 2.51155 2.31649 1.49661 3.4173C0.481678 4.51812 -0.0563308 5.97588 0.000128003 7.47211C0.000128003 11.2613 3.98846 15.3996 7.33346 18.2054C8.08031 18.833 9.02459 19.1771 10.0001 19.1771C10.9757 19.1771 11.9199 18.833 12.6668 18.2054C16.0118 15.3996 20.0001 11.2613 20.0001 7.47211C20.0566 5.97588 19.5186 4.51812 18.5036 3.4173C17.4887 2.31649 16.0793 1.6621 14.5835 1.59711ZM11.596 16.9304C11.1493 17.3066 10.5841 17.5129 10.0001 17.5129C9.41617 17.5129 8.85097 17.3066 8.40429 16.9304C4.12263 13.3379 1.66679 9.89127 1.66679 7.47211C1.60983 6.4177 1.9721 5.38355 2.6746 4.59519C3.37709 3.80683 4.36282 3.32823 5.41679 3.26377C6.47077 3.32823 7.45649 3.80683 8.15899 4.59519C8.86149 5.38355 9.22376 6.4177 9.16679 7.47211C9.16679 7.69312 9.25459 7.90508 9.41087 8.06136C9.56715 8.21764 9.77911 8.30544 10.0001 8.30544C10.2211 8.30544 10.4331 8.21764 10.5894 8.06136C10.7457 7.90508 10.8335 7.69312 10.8335 7.47211C10.7765 6.4177 11.1388 5.38355 11.8413 4.59519C12.5438 3.80683 13.5295 3.32823 14.5835 3.26377C15.6374 3.32823 16.6232 3.80683 17.3257 4.59519C18.0282 5.38355 18.3904 6.4177 18.3335 7.47211C18.3335 9.89127 15.8776 13.3379 11.596 16.9271V16.9304Z"
                          fill="#727272"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_514_7172">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </button>
                <!-- <button class="product-info__favorite">
                  <span
                    ><img src="../assets/icons/card/heart.svg" alt=""
                  /></span>
                </button> -->
                <div class="product-info__rating">
                  <StarRating
                    :grade="rating"
                    :max-stars="5"
                    :readonly="false"
                    :active-on-clicl="true"
                  ></StarRating>
                </div>
                <div
                  class="product-info__reviews-count"
                  v-if="reviews.length > 0"
                >
                  {{ reviewsNumber }}
                </div>
              </div>
              <div class="product-info__code">
                {{ $t("detail.articule") }} <span>{{ product.artikul }}</span>
              </div>
            </div>

            <div class="product-info__main">
              <div class="product-info__name">{{ product.title }}</div>
              <div
                class="product-info__typeTitle"
                v-if="product.price_type_title"
              >
                {{ $t("typeTitle") }}: {{ product.price_type_title }}
              </div>
              <div class="product-info__price">
                {{ formatPrice(countBy) }}
                <span
                  class="uslovieSale"
                  v-if="product.funds_1 || product.funds_2 || false"
                  >{{ $t("uslovieSale") }}
                  <div class="dropsSale" v-if="product.funds_1">
                    {{ product.funds_1_description.name }}
                  </div>
                  <div class="dropsSale" v-else-if="product.funds_2">
                    {{ product.funds_2_description.name }}
                  </div>
                </span>
              </div>
              <div class="radioButtons" v-if="product.stock > 0">
                <div class="radioBlock">
                  <input
                    type="radio"
                    id="radioBlock1"
                    v-model="typeBuy"
                    :value="1"
                    @click="this.inCart = false"
                  />
                  <label for="radioBlock1" style="white-space: nowrap">{{
                    $t("inPieces")
                  }}</label>
                </div>
                <div class="radioBlock">
                  <input
                    type="radio"
                    id="radioBlock2"
                    v-model="typeBuy"
                    :value="0"
                    @click="this.inCart = false"
                  />
                  <label for="radioBlock2">{{
                    $t("box_count", { value: product.features.quantity })
                  }}</label>
                </div>
              </div>
              <div class="product-info__quantity">
                <div class="product-info__counter" v-if="product.stock > 0">
                  <button
                    :disabled="count < 2 ? true : false"
                    class="product-info__remove"
                    @click="removeProduct"
                  >
                    -
                  </button>
                  <input
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    @input="noZero"
                    :style="
                      'width:' +
                      parseInt(
                        parseInt(String(count).length) * parseInt(10) +
                          parseInt(20)
                      ) +
                      'px;'
                    "
                    class="inputCount"
                    v-model="count"
                  />
                  <button
                    :disabled="
                      typeBuy === 1 && product.total_count <= count
                        ? true
                        : typeBuy === 0 && product.box_count <= count
                        ? true
                        : false
                    "
                    class="product-info__add"
                    @click="addProduct"
                  >
                    +
                  </button>
                </div>
                <div class="product-info__availability">
                  <span v-if="product.stock > 0">{{
                    $t("detail.available")
                  }}</span>
                  <span v-if="product.stock === 0">{{
                    $t("detail.notAvailable")
                  }}</span>
                </div>
              </div>

              <button
                v-if="!inCart && product.stock > 0"
                class="product-info__btn main-btn"
                @click="cartAdd"
              >
                {{ $t("detail.inBasket") }}
              </button>
              <button
                v-if="inCart && product.stock > 0"
                style="background: white; color: #d81921"
                class="product-info__btn main-btn"
                @click="deleteCart(product)"
              >
                {{ $t("detail.noBasket") }}
              </button>
            </div>

            <div
              class="product-info__characteristics"
              v-if="product.features != null"
            >
              <div class="product-info__small-title">
                {{ $t("detail.allCharacteristic") }}
              </div>
              <ul class="product-info__list">
                <li>
                  <div class="product-info__text">
                    {{ $t("detail.type") }}:
                    <span>{{ product.features.type }}</span>
                  </div>
                </li>
                <li>
                  <div class="product-info__text">
                    {{ $t("detail.purpose") }}:
                    <span>{{ product.features.purpose }}</span>
                  </div>
                </li>
                <li>
                  <div class="product-info__text">
                    {{ $t("detail.size") }}:
                    <span>{{ product.features.size }}</span>
                  </div>
                </li>
                <li>
                  <div class="product-info__text">
                    {{ $t("detail.amount") }}:
                    <span>{{ product.features.quantity }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="product-card__additional">
          <TabItem
            :tabItems="tabs"
            :selected="selectedTab"
            :styleTab="true"
            @setTab="selectTab"
          ></TabItem>
          <TabBlock :isSelected="selectedTab === tabs[0].id">
            <div class="product-info__small-title">
              {{ $t("detail.titleDesc") }}
            </div>
            <div class="product-info__text" v-html="product.description"></div>
          </TabBlock>
          <TabBlock :isSelected="selectedTab === tabs[1].id">
            <div class="product-info__small-title">
              {{ $t("detail.allCharacteristic") }}
            </div>

            <ul class="product-info__list" v-if="product.features != null">
              <li>
                <div class="product-info__text">
                  {{ $t("detail.type") }}:
                  <span>{{ product.features.type }}</span>
                </div>
              </li>
              <li>
                <div class="product-info__text">
                  {{ $t("detail.purpose") }}:
                  <span>{{ product.features.purpose }}</span>
                </div>
              </li>
              <li>
                <div class="product-info__text">
                  {{ $t("detail.size") }}:
                  <span>{{ product.features.size }}</span>
                </div>
              </li>
              <li>
                <div class="product-info__text">
                  {{ $t("detail.amount") }}:
                  <span>{{ product.features.quantity }}</span>
                </div>
              </li>
            </ul>
          </TabBlock>
          <TabBlock :isSelected="selectedTab === tabs[2].id">
            <div class="product-info__small-title">
              {{ $t("detail.reviewsAbout") }} {{ product.title }}
            </div>
            <div class="product-info__reviews">
              <div
                class="product-info__review product-review"
                v-for="(review, id) in reviews"
                :key="id"
              >
                <ReviewComponent
                  :type="'public'"
                  :review="review"
                ></ReviewComponent>
              </div>
            </div>
            <button
              class="product-info__add-review second-btn"
              @click="checkOt"
            >
              {{ $t("detail.writeReview") }}
            </button>
          </TabBlock>
          <TabBlock :isSelected="selectedTab === tabs[3].id">
            <div class="product-info__small-title">{{ payment.title }}</div>
            <div class="product-info__text" v-html="payment.description"></div>
          </TabBlock>
          <TabBlock :isSelected="selectedTab === tabs[4].id">
            <div class="product-info__small-title">{{ delivery.title }}</div>
            <div class="product-info__text" v-html="delivery.description"></div>
          </TabBlock>
        </div>
      </div>
    </div>
    <div
      class="product-card__similar similar-products"
      v-if="product.similars.length > 0"
    >
      <div class="similar-products__container _container">
        <div class="similar-products__top">
          <h2 class="similar-products__title main-title">
            {{ $t("detail.similar") }}
          </h2>
          <div class="similar-products__navigation">
            <div class="similar-products__right navigation-next">
              <span><img src="../assets/icons/swiper/arrow.svg" alt="" /></span>
            </div>
            <div class="similar-products__left navigation-prev">
              <span><img src="../assets/icons/swiper/arrow.svg" alt="" /></span>
            </div>
          </div>
        </div>

        <swiper
          :modules="modules"
          :slides-per-view="5"
          :breakpoints="breakpoints"
          :space-between="20"
          :navigation="navigation"
          class="similar-products__swiper"
          style="padding: 1px 0"
        >
          <swiper-slide v-for="(product, idx) in product.similars" :key="idx">
            <router-link :to="'/product/' + product.slug">
              <ProductItem :product="product"></ProductItem>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <ModalComponent :isOpen="isOpenModal" @close="isOpenModal = false">
      <div class="product-card__modal review-modal">
        <h2 class="review-modal__title">{{ $t("detail.writeReview") }}</h2>
        <div class="review-modal__items">
          <div class="review-modal__item">
            <div class="review-modal__name">{{ $t("detail.starReview") }}</div>
            <StarRating
              :grade="review.rating"
              :max-stars="5"
              @getStars="getStars"
            ></StarRating>
          </div>
          <div class="review-modal__item">
            <div class="review-modal__name">{{ $t("detail.comment") }}</div>
            <textarea
              v-model="review.review"
              class="review-modal__textarea main-input"
              :placeholder="$t('placeholder.isLike')"
            ></textarea>
          </div>
          <div class="review-modal__item">
            <div class="review-modal__name">{{ $t("detail.fio") }}</div>
            <input
              type="text"
              v-model="review.name"
              class="review-modal__input main-input"
              :placeholder="$t('placeholder.nsurn')"
            />
          </div>
        </div>
        <div class="review-modal__bottom">
          <a
            :href="'https://admin.vivid.kz' + getFiles.reviewPolicy"
            download
            class="review-modal__rule"
            style="text-decoration: underline"
            >{{ $t("detail.pravila") }}</a
          >
          <div class="review-modal__btns">
            <button class="review-modal__btn main-btn" @click="addReview">
              {{ $t("detail.writeReview") }}
            </button>
            <button
              class="review-modal__btn second-btn"
              @click="isOpenModal = false"
            >
              {{ $t("detail.cancel") }}
            </button>
          </div>
        </div>
      </div>
    </ModalComponent>
  </div>
</template>

<script>
import ProductItem from "@/components/ProductItem";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { Navigation } from "swiper";
import TabBlock from "@/components/TabBlock";
import TabItem from "@/components/TabItem";
import ThumbsSwiper from "@/components/ThumbsSlider";
import ReviewComponent from "@/components/ReviewComponent";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import axios from "axios";
import ModalComponent from "@/components/ModalComponent";
import StarRating from "@/components/StarRating";
import BreadCrumbs from "../components/UI/BreadCrumbs.vue";
import { useToast } from "vue-toastification";
export default {
  components: {
    ModalComponent,
    ReviewComponent,
    ThumbsSwiper,
    TabItem,
    TabBlock,
    ProductItem,
    Swiper,
    StarRating,
    SwiperSlide,
    BreadCrumbs,
  },
  data() {
    return {
      isOpenModal: false,
      product: null,
      typeBuy: 1,
      delivery: {},
      main_imageP: null,
      countBy: null,
      countBy1: null,
      imagesP: null,
      inCart: false,
      payment: {},
      maxCount: false,
      reviews: {},
      review: {
        name: "",
        review: "",
        rating: 0,
      },
      rating: null,
      count: 1,
      selectedTab: null,
      tabs: [
        {
          id: 0,
          name: "detail.description",
        },
        {
          id: 1,
          name: "detail.characteristic",
        },
        {
          id: 2,
          name: "detail.reviews",
        },
        {
          id: 3,
          name: "detail.payment",
        },
        {
          id: 4,
          name: "detail.delivery",
        },
      ],

      navigation: {
        nextEl: ".similar-products__left",
        prevEl: ".similar-products__right",
      },
      breakpoints: {
        1366.98: {
          slidesPerView: 5,
        },
        991.98: {
          slidesPerView: 4,
        },
        767.98: {
          slidesPerView: 3,
        },
        567.98: {
          slidesPerView: 2.5,
          spaceBetween: 10,
        },
        479.98: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        0: {
          slidesPerView: 1.5,
          spaceBetween: 10,
        },
      },
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    ...mapState({
      productId: "product",
      auth: "auth",
      favorites: "favorites",
    }),
    ...mapGetters(["getCart", "getAuth", "getOrders", "getFiles"]),
    reviewsNumber() {
      let quantity = this.reviews.length;
      return quantity == 1 || (quantity > 19 && quantity % 10 == 1)
        ? `${quantity} отзыв`
        : (quantity > 1 && quantity < 5) ||
          (quantity > 19 && quantity % 10 > 1 && quantity % 10 < 5)
        ? `${quantity} отзыва`
        : `${quantity} отзывов`;
    },
    formatСode() {
      return this.product?.artikul
        ?.toString()
        .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$& ");
    },
    mainImg() {
      return this.main_imageP;
    },
    imagesPP() {
      return this.imagesP;
    },
    favorite() {
      return this.favorites.find(
        (item) => parseInt(item.product_id) === this.product.id
      );
    },
    // formatPrice() {
    //   return this.product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " ₸";
    // },
  },
  mounted() {},
  created() {
    this.fetchProduct();    
    this.fetchOrders();
    this.selectedTab = this.tabs[0].id;
  },
  methods: {
    ...mapMutations(["addToCart", "addFavoriteToLocal", "deleteProduct"]),
    ...mapActions(["addFavorite", "fetchOrders", "fetchFavorites", "removeFavorite"]),
    addFavoriteProduct() {
      if (this.auth) {
        let productId = this.product.id;
        let favoriteProduct = this.favorites.find(
          (product) => parseInt(product.product_id) == productId
        );
        if (favoriteProduct) {
          this.removeFavorite(favoriteProduct.id);
        } else {
          this.addFavorite({ product_id: this.product.id });
        }
        this.fetchFavorites();
      } else {
        this.addFavoriteToLocal(this.product);
      }
    },
    checkOt() {
      const foundProduct = this.getOrders.find(elem => {
        return elem.products.some(item => item.product_id === this.product.id) && elem.status === "Выдан";
      });
      if (this.getAuth && foundProduct) {
        this.isOpenModal = true;
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
      } else {
        this.toast.warning(this.$t('reviewText'))
      }
    },
    cartAdd() {
      this.addToCart({
        ...this.product,
        quantity: this.count,
        typeBuy: this.typeBuy,
      });
      this.inCart = !this.inCart;
    },
    noZero(e) {
      let enteredValue = parseInt(e.target.value, 10);

      if (this.typeBuy === 1) {
        if (enteredValue > this.product.total_count) {
          this.count = this.product.total_count;
        } else {
          this.count = enteredValue;
        }
        this.countBy = this.countBy1 * this.count;
      } else {
        if (enteredValue > this.product.box_count) {
          this.count = this.product.box_count;
        } else {
          this.count = enteredValue;
        }
        this.countBy = this.product.features.quantity * this.countBy1 * this.count;
      }

      this.inCart = false;
      e.target.value = e.target.value.replace(/^0/, "");
    },
    deleteCart() {
      this.deleteProduct(this.product);
      this.inCart = !this.inCart;
      this.count = 1;
    },
    getStars(data) {
      this.review.rating = data;
    },
    selectTab(tab) {
      this.selectedTab = tab;
    },
    addProduct() {
      this.count++;
      if (this.typeBuy === 1) {
        if (this.count < this.product.total_count) {
          this.countBy = this.countBy1 * this.count;
        } else {
          (this.countBy = this.countBy1 * this.count), (this.maxCount = true);
        }
      } else {
        if (this.count < this.product.box_count) {
          this.countBy =
            this.product.features.quantity * this.countBy1 * this.count;
        } else {
          (this.countBy =
            this.product.features.quantity * this.countBy1 * this.count),
            (this.maxCount = true);
        }
      }
      this.inCart = false;
    },
    removeProduct() {
      if (this.count > 1) {
        this.count--;
      }
      if (this.typeBuy === 1) {
        if (this.count < this.product.total_count) {
          (this.countBy = this.countBy1 * this.count), (this.maxCount = false);
        }
      } else {
        if (this.count < this.product.box_count) {
          (this.countBy =
            this.product.features.quantity * this.countBy1 * this.count),
            (this.maxCount = false);
        }
      }
      this.inCart = false;
    },
    formatPrice(price) {
      return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " ₸";
    },
    async fetchProduct() {
      await axios
        .get(`V1/product-by-slug`, {
          params: {
            slug: this.$route.params.slug,
          },
          headers: {
            Authorization: localStorage.getItem("access_token")
              ? `Bearer ${localStorage.getItem("access_token")}`
              : null,
          },
        })
        .then((response) => {
          this.product = response.data.data;
          this.delivery = response.data.delivery;
          this.payment = response.data.payment;
          this.reviews = response.data.reviews;
          this.rating = response.data.rating;
          this.countBy = response.data.data.current_price;
          this.countBy1 = response.data.data.current_price;
          if (this.getCart) {
            this.getCart.forEach((item) => {
              if (item.id === this.product.id) {
                (this.inCart = true),
                  (this.count = item.quantity),
                  (this.typeBuy = item.typeBuy || 0);
              }
            });
          }
          (this.main_imageP = this.product.main_image),
            (this.imagesP = this.product.images);
        })

        .catch((error) => {
          console.log(error)
        });

      if (this.typeBuy === 1) {
        if (this.count < this.product.total_count) {
          this.countBy = this.countBy1 * this.count;
        } else {
          (this.countBy = this.countBy1 * this.count), (this.maxCount = true);
        }
      } else {
        if (this.count < this.product.box_count) {
          this.countBy =
            this.product.features.quantity * this.countBy1 * this.count;
        } else {
          (this.countBy =
            this.product.features.quantity * this.countBy1 * this.count),
            (this.maxCount = true);
        }
      }
    },
    addReview() {
      axios
        .post(
          "/V1/review",
          { ...this.review, product_id: this.product.id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        )
        .then((response) => {
          this.isOpenModal = false;
        })
        .catch((errors) => {
        });
    },
  },
  watch: {
    "$route.params.slug"() {
      this.fetchProduct();
    },
    product() {
      (this.main_imageP = this.product.main_image),
        (this.imagesP = this.product.images);
    },
    typeBuy() {
      this.count = 1;
      this.maxCount = false;
      if (this.typeBuy === 1) {
        this.countBy = this.countBy1 * this.count;
      } else {
        this.countBy =
          this.product.features.quantity * this.countBy1 * this.count;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
button:disabled,
button[disabled] {
  background-color: #e3dfde;
}
ul li {
  list-style: none;
}
.form-control:focus-within {
  color: var(--form-control-color);
}

.form-control + .form-control {
  margin-top: 1em;
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: #d81921;
  min-width: 16px;
  width: 16px;
  height: 16px;
  border: 0.15em solid #d81921;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  /* Windows High Contrast Mode */
  background-color: #d81921;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
.radioButtons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  gap: 10px;
  font-size: 12px;
  width: auto;
  .radioBlock {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
.product-card {
  padding: 50px 0 112px 0;
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0 103px 0;
  }

  &__container {
    margin: 0 auto 102px;
    @media (max-width: $mobileSmall + px) {
      margin: 0 auto 80px;
    }
  }

  &__content {
    @media (min-width: $tablet + px) {
      display: flex;
      gap: 0 31px;
      margin: 0 0 72px 0;
    }
    @media (max-width: $tablet + px) {
      margin: 0 0 55px 0;
    }
  }

  &__swiper {
    @media (max-width: $tablet + px) {
      margin: 0 0 47px 0;
    }
  }

  .tab-content {
    padding: 35px 0 0 0;
    @media (max-width: $mobileSmall + px) {
      padding: 46px 0 0 0;
    }
  }

  .tab-list {
    gap: 16px 10px;
    @media (max-width: $mobile + px) {
      flex-wrap: wrap;
    }
  }

  .tab-link {
    border-radius: 10px;
    padding: 16px 33px;
    border: 1px solid #d81921;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #d81921;

    &.active {
      background: #d81921;
      color: #ffffff;
    }
  }
}

.product-info {
  flex: 1 1 auto;
  &__typeTitle {
    margin-top: -15px;
    margin-bottom: 5px;
    color: gray;
  }
  &__icon {
    transition: all 0.3s ease 0s;

    svg {
      display: inline-block;
      width: 18px;
      height: 18px;
      transition: all 0.5s ease 0s;

      &:hover {
        path {
          fill: #d81921;
        }
      }
    }

    &.active {
      animation: heartPulse 0.5s ease both;
      svg {
        path {
          fill: #d81921;
        }
      }
    }

    @keyframes heartPulse {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(1.3);
      }
    }

    @media (max-width: $mobileSmall + px) {
      img {
        width: 19.22px;
        height: 17.58px;
      }
      top: 15px;
      right: 14.41px;
    }
  }
  &__top {
    padding: 0 0 14px 0;
    margin: 0 0 15px 0;
    border-bottom: 1px solid #e9e9e9;
    @media (min-width: $pc + px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0 20px;
    }
    @media (max-width: $mobileSmall + px) {
      padding: 0 0 4px 0;
      margin: 0 0 24px 0;
    }
  }

  &__main {
    border-bottom: 1px solid #e9e9e9;
    padding: 0 0 45px 0;
    margin: 0 0 30px 0;
    @media (max-width: $mobileSmall + px) {
      padding: 0 0 32px 0;
      margin: 0 0 34px 0;
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    @media (max-width: $pc + px) {
      flex-wrap: wrap;
      gap: 10px 5px;
      margin: 0 0 11px 0;
    }
  }

  &__favorite {
    margin: 0 13px 0 0;

    span {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__rating {
    margin: 0 10px 0 0;
  }

  &__name {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    margin: 0 0 34px 0;
    @media (max-width: $mobileSmall + px) {
      font-size: 20px;
      margin: 0 0 33px 0;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    gap: 23px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 0 0 19px 0;
  }

  &__code {
    font-weight: 400;
    font-size: 12px;
    line-height: 117%;
    color: #555555;

    span {
      color: #202020;
      font-weight: 600;
    }
  }

  &__quantity {
    display: flex;
    align-items: center;
    gap: 0px 19px;
    margin: 0 0 37px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 26px 0;
    }
  }

  &__counter {
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    display: flex;
  }

  &__add,
  &__remove {
    width: 30px;
    font-size: 20px;
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #cfcfcf;
    border-right: 1px solid #cfcfcf;
    width: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-align: center;
  }

  &__availability {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }

  &__btn {
    padding: 0 63.18px;
    @media (max-width: $mobileSmall + px) {
      width: 100%;
    }
  }

  &__small-title {
    @extend .base-text-500;
    margin: 0 0 16px 0;
    @media (max-width: $mobileSmall + px) {
      font-size: 16px;
      margin: 0 0 5px 0;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }

    span {
      font-weight: 500;
    }
  }

  &__reviews {
    max-width: 867px;
    margin: 0 0 40px 0;
  }

  &__review {
    padding: 30px 0;

    &:first-child {
      padding: 15px 0 30px 0;
    }

    &:last-child {
      padding: 30px 0 0 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #c2c2c2;
    }
  }

  &__add-review {
    padding: 0 40px;
  }
}

.similar-products {
  &__container {
    @media (max-width: $mobile + px) {
      padding: 0 0 0 16px !important;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 46px 0;
    gap: 0 20px;
    @media (max-width: $tablet + px) {
      margin: 0 0 40.5px 0;
    }
    @media (max-width: $mobile + px) {
      padding: 0 16px 0 0;
    }
    @media (max-width: $mobileSmall + px) {
      align-items: flex-end;
      margin: 0 0 35px 0;
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
    gap: 0 8px;
  }
}

.product-swiper {
  display: flex;
  gap: 0 15px;

  &__images {
    display: flex;
    flex-direction: column;
  }

  &__image {
    width: 104px;
    height: 95px;
    border: 1px solid #ececec;
    border-radius: 10px;

    &:not(:last-child) {
      margin: 0 0 10px 0;
    }
  }
}

.review-modal {
  padding: 45px 50px 53px;
  @media (max-width: $tablet + px) {
    padding: 25px 30px 23px;
  }
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    margin: 0 0 40px 0;
  }
  &__items {
    margin: 0 0 65px 0;
    @media (max-width: $tablet + px) {
      margin: 0 0 35px 0;
    }
  }
  &__item {
    display: flex;
    gap: 0 48px;
    align-items: flex-start;
    &:not(:last-child) {
      margin: 0 0 25px 0;
    }
    @media (max-width: $tablet + px) {
      flex-direction: column;
      gap: 15px;
    }
  }
  &__textarea {
    height: 114px;
    resize: none;
  }
  &__name {
    flex: 0 0 120px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    @media (max-width: $tablet + px) {
      flex: 0;
    }
  }
  &__rule {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: underline;
    padding: 0 0 8px 0;
  }
  &__bottom {
    display: flex;
    gap: 0 54px;
    align-items: flex-end;
    justify-content: space-between;
    @media (max-width: $tablet + px) {
      flex-direction: column;
      align-items: stretch !important;
      gap: 15px;
      a {
        text-align: center;
      }
      &__btns {
        button {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
  &__btn {
    padding: 0 42px;
    &:not(:last-child) {
      padding: 0 36px;
      margin: 0 25px 0 0;
    }
  }
  @media (max-width: $tablet + px) {
    &__btns {
      button {
        width: 100%;
        margin-bottom: 10px !important;
      }
    }
  }
}

.uslovieSale {
  font-weight: 500;
  font-size: 12px;
  text-decoration-line: underline;
  color: #d81921;
  cursor: pointer;
  position: relative;
  &:hover {
    .dropsSale {
      display: block;
    }
  }
}

.dropsSale {
  display: none;
  position: absolute;
  top: -30%;
  margin-left: 15px;
  left: 100%;
  background-color: #efefef;
  border-radius: 20px;
  font-weight: 500;
  width: 235px;
  height: 74px;
  padding-left: 20px;
  padding-right: 13px;
  padding-top: 15px;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 17px;
  color: #202020;
  &:after,
  &:before {
    right: 100%;
    top: 30%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #efefef;
    border-width: 10px;
    margin-top: -10px;
  }
  @media screen and (max-width: 762px) {
    top: 110%;
    margin-left: 0px;
    max-width: 200px;
    padding-right: 20px;
    left: 0%;
    &:after,
    &:before {
      top: -14%;
      left: 15%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: #efefef;
      border-width: 10px;
      margin-top: -10px;
    }
  }
}
</style>